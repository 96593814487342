import {TokenStats} from "@/components/Perps/types";
import React, {useEffect, useState} from "react";
import classNames from "classnames";

export interface Props {
  label?: string | undefined;
  value: any;
  children: (v: TokenStats) => any;
  prevRef: React.MutableRefObject<any>;
  isLoading: boolean;
  isError: boolean;
  data: TokenStats | undefined;
}

export const TokenStat = ({label, children, value, prevRef, isError, isLoading, data}: Props) => {
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (value > prevRef.current) {
      setBackgroundColor('text-green-500');
    } else if (value < prevRef.current) {
      setBackgroundColor('text-red-500');
    }
    const timeout = setTimeout(() => {
      setBackgroundColor(undefined);
    }, 500);

    prevRef.current = value;
    return () => clearTimeout(timeout);
  }, [value, label]);

  return (
    <div className="flex flex-col items-end">
      {label && <span className="text-xs text-neutral-content font-light">{label}</span>}
      { isLoading && <div className="min-w-[100px] min-h-[20px] rounded-md bg-slate-600 animate-pulse m-2"/> }
      { isError && <span className="text-sm text-put">Error</span> }
      { !isLoading && !isError && data &&
        <div className={classNames('text-xl lg:text-3xl', {
          'animate-shine': backgroundColor !== undefined,
          [backgroundColor || '']: backgroundColor !== undefined,
        })}>
          {children(data)}
        </div>
      }
    </div>
  )
}