import {PerpPositionStatus} from "@/components/Perps/types";
import {LimitOrderView} from "@/components/Perps/closePosition/LimitOrderView";
import React from "react";
import {PositionHeader} from "@/components/Perps/positions/PositionHeader";
import {PnLDisplay} from "@/components/Perps/positions/PnLDisplay";
import {MdClose} from "react-icons/md";
import {ClosePositionSideSelect} from "@/components/Perps/closePosition/ClosePositionSideSelect";
import {MarketClosePositionView} from "@/components/Perps/closePosition/MarketClosePositionView";
import {CHAIN_ID, isBlast, isMainnet} from "@/util/constants";
import {useQuery} from "@tanstack/react-query";
import {fetchClosePositionOrder} from "@/api/perpsDataFetcher";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {mainnet, sepolia} from "wagmi/chains";
import {useIsDesktop} from "@/hooks/useIsDesktop";

export type Props = {
  perpPositionStatus: PerpPositionStatus;
  closePositionType: ClosePositionType,
  onClosePositionTypeChange: (side: ClosePositionType) => any;
  onClose: () => void;
}

export type ClosePositionType = 'market' | 'tp' | 'sl';

export const ClosePositionView = ({perpPositionStatus, closePositionType, onClosePositionTypeChange, onClose}: Props) => {
  const {address} = useCurrentUser();

  const existingOrdersQuery = useQuery({
    queryKey: ['positionOrders', perpPositionStatus.position.id],
    queryFn: async () => await fetchClosePositionOrder(perpPositionStatus.position.id),
    enabled: !!address && !!perpPositionStatus
  });

  const renderLimitOrderView = () => {
    if (existingOrdersQuery.isLoading) {
      return (
        <div className="w-[400px] h-[10px]">
          <div className="w-full h-full bg-slate-600 animate-pulse"/>
        </div>
      );
    }

    const orderType = closePositionType === 'tp' ? 0 : 1;

    const existingOrder = existingOrdersQuery.data?.find(v => v.orderType === orderType);
    return (
      <LimitOrderView
        key={`${perpPositionStatus.position.id}_${closePositionType}`}
        perpPositionStatus={perpPositionStatus}
        closePositionType={closePositionType}
        existingOrder={existingOrder}
        onSave={existingOrdersQuery.refetch}
      />
    )
  }

  return (
    <div className="flex flex-col h-full pb-safe">
      <div className="w-full flex flex-row items-center justify-between p-2 h-[52px]">
        <div className="flex flex-row gap-4 items-center">
          <PositionHeader
            className="text-xs"
            position={perpPositionStatus.position}
            token={perpPositionStatus.token}
            onTokenClicked={t => null}/>
          <PnLDisplay positionStatus={perpPositionStatus}/>
        </div>
        <MdClose onClick={onClose} size={24} className="hover:scale-110 cursor-pointer"/>
      </div>
      <hr className="border-neutral-content/20 w-full"/>
      <ClosePositionSideSelect side={closePositionType} onChange={onClosePositionTypeChange}/>
      {
        closePositionType === 'market'
          ? <MarketClosePositionView positionStatus={perpPositionStatus} onSuccess={onClose}/>
          : renderLimitOrderView()
      }
    </div>
  );
}
