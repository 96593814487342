import {PerpConfig, TokenStats} from "@/components/Perps/types";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {fetchTokenStats} from "@/api/perpsDataFetcher";
import {getAddress} from "viem";
import {isUsdToken} from "@/util/chainConstants";
import {PerpTokenView} from "@/components/Perps/header/PerpTokenView";
import {TokenStat} from "@/components/Perps/header/TokenStat";
import React, {useRef} from "react";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import {prettifyNumber} from "@/util/converters";
import classNames from "classnames";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import {IoChevronDown, IoChevronUp} from "react-icons/io5";
import {Timeframe, useUserSelection} from "@/contexts/UserSelectionContext";
import {Tabs} from "@/components/Tabs";
import {DropdownOption} from "@/components/Dropdown";
import {useQuery} from "@tanstack/react-query";

export interface Props {
  perpConfig: PerpConfig;
  onChange: (perpConfig: PerpConfig) => void;
}

const options: DropdownOption<Timeframe>[] = [
  { label: "1H ", value: 1 },
  { label: "4H ", value: 4 },
  { label: "12H ", value: 12 },
  { label: "24H ", value: 24 }
];

export const PerpHeader = ({ perpConfig, onChange }: Props) => {
  const isDesktop = useIsDesktop();
  const [showDetailsMobile, setShowDetailsMobile] = React.useState(false);
  const {userSelections, handleSelection} = useUserSelection();

  const token = perpConfig.token;
  const {showInUsd, ethPrice, } = useEthPrice();
  const isUSD = isUsdToken(token.address);
  const query = useQuery({
    queryKey: ["token_stats", token.address],
    queryFn: async () => await fetchTokenStats(getAddress(token.address)),
    refetchInterval: 3 * 1000, // 10 seconds
    gcTime : 3 * 1000, // 30 seconds
  });

  const getValue = (value: "volume" | "change", tokenStats: TokenStats) => {
    switch (userSelections.timeframe) {
      case 1:
        return value === "volume" ? tokenStats.oneHourVolumeUsd : tokenStats.oneHourChange;
      case 4:
        return value === "volume" ? tokenStats.fourHourVolumeUsd : tokenStats.fourHourChange;
      case 12:
        return value === "volume" ? tokenStats.twelveHourVolumeUsd : tokenStats.twelveHourChange;
      default:
        return value === "volume" ? tokenStats.oneDayVolumeUsd : tokenStats.oneDayChange;
    }
  }

  const oneDayChangeRef = useRef(query.data?.oneDayChange);
  const oneDayVolumeUsdRef = useRef(query.data?.oneDayVolumeUsd);
  const priceRef = useRef(isUSD ? 1 / (query.data?.price || 1) : query.data?.price);

  const renderSecondaryStats = () => {
    return <>
      <TokenStat
        isLoading={query.isLoading}
        isError={query.isError}
        data={query.data}
        label="Market Cap"
        value={query.data?.marketCap}
        prevRef={priceRef}
        children={v =>
          <span className="flex flex-row items-center text-sm">
            <span className="">$</span>{prettifyNumber(v.marketCap)}
          </span>}
      />
      <TokenStat
        {...query}
        label={`${userSelections.timeframe}h Change`}
        value={query.data ? getValue("change", query.data) : undefined}
        prevRef={oneDayChangeRef}
        children={v => {
          const value = getValue("change", v);
          return <div className={classNames("flex flex-row items-center text-sm", {
            "text-put": value < 0,
            "text-call": value > 0,
            "text-neutral-content": value === 0,
          })}>
            {value > 0 && "+"}{value.toFixed(2)}%
          </div>;
        }}
      />
      <TokenStat
        {...query}
        label={`${userSelections.timeframe}h Volume`}
        value={query.data ? getValue("volume", query.data) : undefined}
        prevRef={oneDayVolumeUsdRef}
        children={v =>
          <span className="flex flex-row items-center text-sm">
            <span className="">$</span>{prettifyNumber(getValue("volume", v))}
          </span>}
      />
    </>
  }

  return (
    <div className="w-full standard-stack">
      <div className="w-full flex flex-row justify-between">
        <PerpTokenView perpConfig={perpConfig} onChange={onChange} />
        <div className="flex flex-row gap-3 lg:gap-12 justify-between lg:justify-evenly items-center"
             onClick={() => !isDesktop && setShowDetailsMobile(s => !s)}
        >
          {
            !isDesktop &&
            (showDetailsMobile ? <IoChevronUp className="text-neutral-content"/> : <IoChevronDown className="text-neutral-content"/>)
          }
          <TokenStat
            {...query}
            label={isDesktop ? "Price" : undefined}
            value={isUSD ? 1 / (query.data?.price || 1) : query.data?.price}
            prevRef={priceRef}
            children={v =>
              <>
                <FractionTokenPrice
                  exponentClassName="text-xs text-neutral-content"
                  className="text-sm"
                  showInUsd={showInUsd}
                  iconSize={12}
                  price={Number(v.price)}
                  tokenAddress={token.address}
                  tokenType={token.tokenType}
                  showAsFullNFT={false}/>
                {
                  !isDesktop &&
                  <div className={classNames("text-xs text-right", {
                    "text-put": v.oneDayChange < 0,
                    "text-call": v.oneDayChange > 0,
                    "text-neutral-content": v.oneDayChange === 0,
                  })}>
                    {v.oneDayChange > 0 && "+"}{v.oneDayChange.toFixed(2)}%
                  </div>
                }
              </>
            }
          />
          { isDesktop && renderSecondaryStats() }
        </div>
      </div>
      {
        !isDesktop && showDetailsMobile &&
        <div className="standard-stack glassborder rounded-md overflow-hidden m-2">
          <div className="flex flex-row gap-2 lg:gap-12 justify-between lg:justify-evenly items-center p-3">
            { renderSecondaryStats() }
          </div>
          <Tabs<Timeframe>
            className="min-h-[40px] !border-none"
            optionClassName="text-base"
            borderlessSelected={true}
            options={options}
            value={userSelections.timeframe}
            onChange={(value) => handleSelection('timeframe', value)}
          />
        </div>
      }
    </div>
  )
}