import React, {useEffect} from "react";
import {ActionType, PerpToken, PositionTradeHistory} from "@/components/Perps/types";
import {fetchPositionTradeHistory} from "@/api/perpsDataFetcher";
import InfiniteScroll from "react-infinite-scroller";
import {EtherscanTransactionDisplay} from "@/components/Account/EtherscanTransactionDisplay";
import moment from "moment";
import {EthValue} from "@/components/Perps/EthValue";
import {formatEther} from "@/util/converters";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {useEthPrice} from "@/contexts/EthPriceContext";
import classNames from "classnames";
import {getLeverage} from "@/components/Perps/util";
import {capitalizeFirstLetter} from "@/util/helpers";
import {LuArrowDownRight, LuArrowUpRight} from "react-icons/lu";
import {FaSkull} from "react-icons/fa";
import {GiDolphin, GiShrimp, GiSpermWhale} from "react-icons/gi";
import {FaFishFins} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import {useInfiniteQuery} from "@tanstack/react-query";
import {isUsdToken} from "@/util/chainConstants";
import {getTimeAgo} from "@/util/dateutils";

interface Props {
  token: PerpToken;
  className?: string | undefined;
}

export const TradeActivity = ({token, className}: Props) => {
  const [typeFilter, setTypeFilter] = React.useState<ActionType>();
  const typeOptions = ["OPEN", "CLOSE", "LIQUIDATE"];
  const [tokenAddressFilter, setTokenAddressFilter] = React.useState<string>();
  const {ethPrice, showInUsd} = useEthPrice();
  const [maxSize, setMaxSize] = React.useState<number>(5);

  const query = useInfiniteQuery({
    queryKey: ['trade_activity', typeFilter, token.address],
    queryFn: async ({pageParam = "0"}) => await fetchPositionTradeHistory(pageParam, typeFilter, token.address),
    initialPageParam: "0",
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    refetchInterval: 15 * 1000, // 15 seconds
  });

  useEffect(() => {
    setMaxSize(5);
  }, [token.address]);

  useEffect(() => {
    if (query.data) {
      const newMaxSize = query.data.pages.flatMap((p) => p.items).map((trade) => {
        const position = trade.position;
        const size = position.side === "LONG"
          ? BigInt(position.principalRaw) + BigInt(position.downPaymentRaw)
          : BigInt(position.collateralAmountRaw);
        return formatEther(size);
      }).reduce((a, b) => Math.max(a, b), 0);
      if (newMaxSize > maxSize) {
        setMaxSize(newMaxSize * 1.2);
      }
    }
  }, [query.data]);

  const isUsd = isUsdToken(token.address);

  const renderTrade = (trade: PositionTradeHistory) => {
    const position = trade.position;
    // let pnl: bigint | undefined;
    // if (trade.action === "CLOSE") {
    //   pnl = BigInt((trade.data as PositionClosed).payout) - BigInt(position.downPaymentRaw);
    // } else if (trade.action === "LIQUIDATE") {
    //   pnl = BigInt((trade.data as PositionLiquidated).payout) - BigInt(position.downPaymentRaw);
    // }
    // const denom = 10000n;
    // let percentValue = undefined;
    // if (pnl) {
    //   percentValue = Number(pnl * denom / BigInt(position.downPaymentRaw)) / Number(denom) * 100;
    // }

    const isBuy = (position.side === "LONG" && trade.action === "OPEN" || position.side === "SHORT" && trade.action !== "OPEN") !== isUsd;
    const isLiquidation = trade.action === "LIQUIDATE";
    const size = position.side === "LONG"
      ? BigInt(position.principalRaw) + BigInt(position.downPaymentRaw)
      : BigInt(position.collateralAmountRaw);
    const sizeRaw = formatEther(size);

    const getSizeIcon = () => {
      if (sizeRaw < 1) {
        return <GiShrimp className="text-[#e29a86] text-sm" />;
      } else if (sizeRaw < 3) {
        return <FaFishFins className="text-[#f5ff69] text-sm"  />;
      } else if (sizeRaw < 10) {
        return <GiDolphin className="text-[#aaa9b3] text-sm"  />;
      } else {
        return <GiSpermWhale className="text-[#80c9ff] text-sm"  />;
      }
    }

    let side = position.side;
    if (isUsd) {
      side = side === "LONG" ? "SHORT" : "LONG";
    }

    const sizePercent = Math.max(1, sizeRaw / maxSize * 100);

    return (
      <div className="grid grid-cols-5 gap-2 p-2 items-center relative text-neutral-content hover:text-white"
           key={`txn_${position.id}_${trade.transactionHash}`}>
        <div style={{
          width: `${sizePercent}%`,
        }} className={classNames(`absolute h-full z-0`, {
          "bg-call/20": isBuy,
          "bg-put/20": !isBuy,
        })} />
        <div className="text-xs col-span-2">
          <div className="flex flex-row items-center gap-1">
            <span className={classNames("flex flex-row items-center gap-1", {"text-call": isBuy, "text-put": !isBuy })}>
              {isLiquidation ? <FaSkull className="text-white" /> : isBuy ? <LuArrowUpRight /> : <LuArrowDownRight /> }
              {isBuy ? "Buy" : "Sell"}
            </span>
            <FractionTokenPrice
              className="text-xs justify-end col-span-2"
              price={trade.price}
              showInUsd={showInUsd}
              tokenType={token.tokenType}
              tokenAddress={token.address} />
          </div>
          <div>
            { capitalizeFirstLetter(trade.action) } {getLeverage(position)}x {capitalizeFirstLetter(side)}
          </div>
          {/*<span className="text-neutral-content flex flex-row gap-1">*/}
          {/*  */}
          {/*  { capitalizeFirstLetter(trade.action) }*/}
          {/*</span>*/}
        </div>
        <div className="flex">
          <EthValue
            ethPrice={ethPrice}
            className="text-xs"
            id={`size_${position.id}`}
            value={size}
          />
          {/*{*/}
          {/*  pnl &&*/}
          {/*  <EthValue*/}
          {/*    showPlus={true}*/}
          {/*    valueForPercent={pnl + BigInt(position.downPaymentRaw)}*/}
          {/*    value={pnl}*/}
          {/*    original={BigInt(position.downPaymentRaw)}*/}
          {/*    className="flex-col items-end text-xs"*/}
          {/*    iconSize={8}*/}
          {/*  />*/}
          {/*}*/}
          {/*{*/}
          {/*  percentValue &&*/}
          {/*  <span className={classNames("text-xs flex flex-row items-center justify-end", {*/}
          {/*    "text-call": percentValue > 0,*/}
          {/*    "text-put": percentValue < 0,*/}
          {/*    "text-neutral-content": percentValue === 0*/}
          {/*  })}>*/}
          {/*    /!*<span className="text-neutral-content mr-2">Pnl</span>*!/*/}
          {/*    { percentValue > 0 && "+" }*/}
          {/*    { percentValue.toFixed(2) }%*/}
          {/*  </span>*/}
          {/*}*/}
        </div>
        <EtherscanTransactionDisplay
          className="text-right text-xs flex items-start justify-end col-span-2"
          hash={trade.transactionHash}
          label={(
            <>
              <span id={`trade_${trade.transactionHash}`}>{getTimeAgo(trade.timestamp * 1000)}</span>
              <ReactTooltip
                anchorSelect={`#trade_${trade.transactionHash}`}
                id={`trade_${trade.transactionHash}_tooltip`}
                place="top"
                noArrow
                className="z-50"
                content={new Date(trade.timestamp * 1000).toLocaleString([], {timeZoneName: "short"})}
                style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
              />
            </>
          )}
        />
      </div>
    )
  }

  return (
    <div className={twMerge("h-full flex flex-col w-full md:w-[460px]", className || '')}>
      <div className="grid grid-cols-5 bg-glass text-xs text-neutral-content px-2 py-1 w-full">
        <span className="col-span-2">Trade</span>
        <span className="text-center">Size</span>
        {/*<span className="col-span-2 text-right">Price</span>*/}
        <span className="col-span-2 text-right">Timestamp</span>
      </div>
      <div className="h-full w-full overflow-y-scroll no-scrollbar">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => query.fetchNextPage()}
          hasMore={query.hasNextPage}
          className="divide-y divide-neutral-content/20"
          loader={<div className="text-center text-xs text-neutral-content" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {
            query.data?.pages.flatMap((p) => p.items).map(renderTrade)
          }
        </InfiniteScroll>
      </div>
    </div>
  )
}
