import {useQuery} from "@tanstack/react-query";
import {fetchPositions} from "@/api/perpsDataFetcher";
import {bigIntSort, stringSort, Table, TableColumn} from "@/components/Table";
import {ClosePositionOrder, PerpPositionStatus, PerpTradeShare} from "@/components/Perps/types";
import {Button, ButtonType} from "@/components/Button";
import {Modal, useModal} from "@/components/Modal";
import React, {useEffect, useState} from "react";
import {PositionHeader} from "@/components/Perps/positions/PositionHeader";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {SharePerpetualPositionTrade} from "@/components/Sharing/SharePerpetualPositionTrade";
import {MdIosShare} from "react-icons/md";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import {PnLDisplay} from "@/components/Perps/positions/PnLDisplay";
import {multiplyByPrice} from "@/util/converters";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {usePendingTransaction} from "@/contexts/PendingTransactionContext";
import {twMerge} from "tailwind-merge";
import {MobilePosition} from "@/components/Perps/positions/MobilePosition";
import {SizeView} from "@/components/Perps/positions/SizeView";
import * as Tooltips from "@/util/tooltips"
import {
    ClosePositionType,
    ClosePositionView
} from "@/components/Perps/closePosition/ClosePositionView";
import classNames from "classnames";
import {formatUnits} from "viem";
import {calculateSlippage} from "@/components/Perps/closePosition/limitOrderCalculations";

export type Props = {
  onTokenClicked: (token: string) => any,
  onCloseButtonClicked: (position: PerpPositionStatus, buttonType: ClosePositionType) => void,
  className?: string | undefined;
  closePositionType?: ClosePositionType | undefined,
}

export const PerpPositionTable = ({onTokenClicked, onCloseButtonClicked, className, closePositionType}: Props) => {
    const {address} = useCurrentUser();
    const pendingTransaction = usePendingTransaction();

    const query = useQuery({
        queryKey: ['positions', address],
        queryFn: async () => await fetchPositions(address!),
        enabled: !!address,
        refetchInterval: 5 * 1000, // 5 seconds
    });

    useEffect(() => {
        if (!query.isLoading && !query.isRefetching && address) {
            query.refetch();
        }
    }, [pendingTransaction]);

    const [selectedPosition, setSelectedPosition] = useState<PerpPositionStatus>();
    const [perpTradeShareData, setPerpTradeShareData] = useState<PerpTradeShare>();
    const {showInUsd} = useEthPrice();

    const handleShareClick = (positionStatus: PerpPositionStatus) => {
        setPerpTradeShareData({
            id: positionStatus.position.id,
            action: "OPEN",
            side: positionStatus.position.side,
            token: positionStatus.token
        });
        sharePerpetualPositionModal.show();
    };

    const closeModal = useModal();
    const sharePerpetualPositionModal = useModal();

    useEffect(() => {
        if (selectedPosition) {
            closeModal.show();
        } else {
            closeModal.hide();
        }
    }, [selectedPosition]);

    useEffect(() => {
        if (!closeModal.modalVisible) {
            setSelectedPosition(undefined);
        }
    }, [closeModal.modalVisible]);

    const getExistingLimitPrice = (p: PerpPositionStatus, existingOrder?: ClosePositionOrder | undefined) => {
        const isLong = p.position.side === "LONG";
        if (existingOrder) {
            const slippage = calculateSlippage(p);
            const makerRaw = parseFloat(formatUnits(existingOrder.makerAmount, isLong ? p.token.decimals : 18));
            const takerRaw = parseFloat(formatUnits(existingOrder.takerAmount, isLong ? 18 : p.token.decimals));
            return (isLong ? takerRaw / makerRaw * slippage : makerRaw / takerRaw / slippage);
        }
        return undefined;
    }

    const columns: TableColumn<PerpPositionStatus>[] = [
        {
            id: "position",
            label: "Position",
            valueRenderer: d => {
                const id = `perp_share_${d.position.id}`;
                return <div className="flex flex-row items-center gap-2">
                    <div
                      id={id}
                      className="hover:scale-110 text-neutral-content hover:cursor-pointer hover:text-white"
                      onClick={() => handleShareClick(d)}>
                        <MdIosShare size={22}/>
                        <ReactTooltip
                          anchorSelect={`#${id}`}
                          id={`tooltip_${id}`}
                          place="left"
                          noArrow
                          className="z-50"
                          content="Share Your Position"
                          style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
                        />
                    </div>
                    <PositionHeader
                      position={d.position}
                      token={d.token}
                      onTokenClicked={t => {
                          onCloseButtonClicked(d, 'market');
                          onTokenClicked(t.symbol);
                      }}/>
                </div>;
            },
            sort: stringSort(d => d.token.symbol),
            span: 3,
        },
        {
            id: "pnl",
            label: "PnL",
            valueRenderer: d => <PnLDisplay positionStatus={d} key={"position_pnl_" + d.position.id}/>,
            tooltip: Tooltips.ACTIVE_POSITIONS_PNL,
            align: "left",
            span: 2,
        },
        {
            id: "size",
            label: "Size",
            tooltip: Tooltips.ACTIVE_POSITIONS_SIZE,
            valueRenderer: d => <SizeView positionStatus={d} key={"position_size_" + d.position.id}/>,
            sort: bigIntSort((d) => d.position.side === "LONG"
              ? multiplyByPrice(d.position.collateralAmountRaw, d.markPrice)
              : BigInt(d.position.collateralAmountRaw)),
            align: "center",
            span: 2,
        },
        {
            id: "entryPrice",
            label: "Entry Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.position.entryPrice}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                showInUsd={showInUsd}
                iconSize={12}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            tooltip: Tooltips.ACTIVE_POSITIONS_ENTRY_PRICE,
            align: "center",
            span: 2,
        },
        {
            id: "marketPrice",
            label: "Market Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.markPrice}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                showInUsd={showInUsd}
                iconSize={12}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            align: "center",
            tooltip: Tooltips.ACTIVE_POSITIONS_MARKET_PRICE,
            span: 2,
        },
        {
            id: "liquidationPrice",
            label: "Liq. Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.liquidationPrice}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                showInUsd={showInUsd}
                iconSize={12}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            align: "center",
            tooltip: Tooltips.ACTIVE_POSITIONS_LIQ_PRICE,
            span: 2,
        },
        {
            id: "actions",
            label: "",
            valueRenderer: d => {
                const tpPrice = getExistingLimitPrice(d, d.tpOrder);
                const slPrice = getExistingLimitPrice(d, d.slOrder);

                return (
                  <div className="grid grid-cols-3 grid-rows-2 gap-0.5 h-[40px]">
                      <Button
                        buttonType={ButtonType.SECONDARY}
                        className="text-sm !rounded-r-none col-span-2 row-span-2"
                        onClick={() => onCloseButtonClicked(d, 'market')}>
                          Cash Out
                      </Button>
                      <Button
                        buttonType={ButtonType.NEUTRAL}
                        className={classNames("text-sm !rounded-l-none !rounded-b-none col-span-1 row-span-1 !p-0", {
                            "!text-call !border-call": d.tpOrder !== undefined
                        })}
                        onClick={() => onCloseButtonClicked(d, 'tp')}
                        tooltip={<div className="flex flex-row gap-2 items-center">
                            <span>TP:</span>
                            {
                                tpPrice ?
                                  <FractionTokenPrice
                                    price={tpPrice}
                                    tokenAddress={d.token.address}
                                    iconSize={12}
                                    exponentClassName="flex flex-row items-center text-xs text-neutral-content"/> :
                                  "Unset"
                            }
                        </div>}
                      >
                          TP
                      </Button>
                      <Button
                        buttonType={ButtonType.NEUTRAL}
                        className={classNames("text-sm !rounded-l-none !rounded-t-none col-span-1 row-span-1 !p-0", {
                            "!text-put !border-put": d.slOrder !== undefined
                        })}
                        onClick={() => onCloseButtonClicked(d, 'sl')}
                        tooltip={<div className="flex flex-row gap-2 items-center">
                            <span>SL:</span>
                            {
                                slPrice ?
                                  <FractionTokenPrice
                                    price={slPrice}
                                    tokenAddress={d.token.address}
                                    iconSize={12}
                                    exponentClassName="flex flex-row items-center text-xs text-neutral-content"/> :
                                  "Unset"
                            }
                        </div>}>
                          SL
                      </Button>
                  </div>
                );
            }
        }
    ];

    const onSuccess = (pnlPositive: boolean, shareTrade: PerpTradeShare) => {
        query.refetch();
        closeModal.hide();
        if (pnlPositive) {
            setPerpTradeShareData(shareTrade);
            sharePerpetualPositionModal.show();
        }
    }

    return (
      <>
          <Table<PerpPositionStatus>
            id="perp_positions"
            className={twMerge("h-full border-none rounded-none no-scrollbar !bg-transparent", className || '')}
            columns={columns}
            data={query.data}
            isLoading={query.isLoading}
            mobileRowRenderer={d =>
              <MobilePosition
                handleShareClick={handleShareClick}
                positionStatus={d}
                setSelectedPosition={setSelectedPosition}
                onTokenClicked={onTokenClicked}/>
            }
            emptyText="No active positions, open a position to get started."
          />
          <Modal {...closeModal} controlled={true}>
              {
                selectedPosition &&
                <ClosePositionView
                  onClose={closeModal.hide}
                  closePositionType={closePositionType || "market"}
                  onClosePositionTypeChange={t => onCloseButtonClicked(selectedPosition, t)}
                  perpPositionStatus={selectedPosition}
                />
              }
          </Modal>
          {
              perpTradeShareData && (
                <Modal {...sharePerpetualPositionModal} title="Share">
                    <SharePerpetualPositionTrade perpTradeShare={perpTradeShareData}/>
                </Modal>
            )
          }
        </>
    )
}
