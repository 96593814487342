import {Address, Hex} from "viem";
import {PermitSingle} from "@uniswap/permit2-sdk";

export interface FractionNFTCollection {
  name: string;
  symbol: string;
  contractAddress: Address;
  fractionSize: number;
}

export interface PerpParameters {
  maxLeverage: number;
}

export interface PerpConfig {
  token: PerpToken;
  parameters: PerpParameters;
  dexScreenerPair : DexScreenerPair;
}
export interface PerpConfig2 {
  token: PerpToken;
  parameters: PerpParameters;
  tokenStats : TokenStats;
  openInterest: TokenOpenInterestStats;
  boost?: Boost | undefined;
  chainId: number;
}

export interface Token {
  address: Address;
  symbol: string;
  name: string;
  decimals: number;
  imageUrl?: string | undefined;
}

export enum TokenType {
  ERC20 = 'ERC20',
  FLC_FRACTION = 'FLC_FRACTION',
  ERC404 = 'ERC404',
  DN404 = 'DN404',
  ERC404V2 = 'ERC404V2'
}

export interface PerpToken extends Token {
  imageUrl: string;
  nftAddress: string | undefined;
  pairAddress: Address;
  maxLeverage: number;
  tokenType: TokenType;
  hasUniV2Pools: boolean;
  twitterHandle: string | undefined;
  maxOpenInterest: number | undefined | null;
  maxShortPrincipal: number | undefined | null;
}

export interface PerpQuote {
  token: PerpToken;
  side: 'LONG' | 'SHORT';
  downPayment: bigint;
  outputSize: bigint;
  outputSizeInETH: bigint;
  fee: bigint;
  entryPrice: number;
  liquidationPrice: number;
  swapQuote: SwapQuote;
  hourlyBorrowFee: number;
  errorMessage: string | undefined;
  principal: bigint;
}

export interface SwapConfig {
  in: Token;
  amount: bigint;
  out: Token;
  poolFee: number;
  quoteType: 'EXACT_IN' | 'EXACT_OUT';
  recipient: Address;
  useUniversalRouter: boolean;
  maxSlippage: number;
  permit2Permit?: Permit2Permit | undefined;
  orderDuration: number;
  includePrices: boolean;
}

export interface TokenToUsdPrice {
  [key: string]: number;
}

export interface SwapQuote {
  swapConfig: SwapConfig;
  quoteAmount: bigint;
  priceImpact: number;
  tradePaths: TradePath[];
  functionCallData: FunctionCallData;
  tokenToUsdPrice: TokenToUsdPrice;
}
export interface Permit2Permit extends PermitSingle {
  signature: string;
}

export interface TradePath {
  percent: number;
  path: string[];
  protocol: string;
}

export interface DexScreenerPair {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  labels: string[];
  baseToken: Token;
  quoteToken: Token;
  priceNative: string;
  priceUsd: string;
  txns: {
    m5: {
      buys: number;
      sells: number;
    };
    h1: {
      buys: number;
      sells: number;
    };
    h6: {
      buys: number;
      sells: number;
    };
    h24: {
      buys: number;
      sells: number;
    };
  };
  volume: {
    h24: number;
    h6: number;
    h1: number;
    m5: number;
  };
  priceChange: {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
  };
  liquidity: {
    usd: number;
    base: number;
    quote: number;
  };
  fdv: number;
  pairCreatedAt: number;
}

export interface PerpPositionStatus {
  position: Position;
  token: PerpToken;
  netValue: bigint;
  markPrice: number;
  liquidationPrice: number;
  interestPaid: bigint;
  interestPaidEthValue: bigint;
  apr: number;
  fee: bigint;
  hasError: boolean;
  tpOrder?: ClosePositionOrder | undefined;
  slOrder?: ClosePositionOrder | undefined;
}

export interface PositionOpened {
  id: number;
  trader: Address;
  downPayment: bigint;
  principal: bigint;
  collateralAmount: bigint;
  feesToBePaid: bigint;
  currency: Address;
  collateralCurrency: Address;
}

export interface PositionLiquidated {
  id: number;
  trader: Address;
  payout: bigint;
  feeAmount: bigint;
  interestPaid: bigint;
  principalRepaid: bigint;
}

export interface PositionClosed {
  id: number;
  trader: Address;
  payout: bigint;
  feeAmount: bigint;
  interestPaid: bigint;
  principalRepaid: bigint;
}

export interface Position {
  id: number;
  poolAddress: Address;
  openTimestamp: number;
  side: 'LONG' | 'SHORT';
  traderAddress: Address;
  currencyAddress: Address;
  collateralCurrencyAddress: Address;
  lastFundingTimestamp: number;
  downPaymentRaw: bigint;
  downPayment: number;
  principalRaw: bigint;
  principal: number;
  collateralAmountRaw: bigint;
  collateralAmount: number;
  feesToBePaid: number;
  feesToBePaidRaw: bigint;
  entryPrice: number;
  leverage: bigint;
}

export type ActionType = 'OPEN' | 'CLOSE' | 'LIQUIDATE';
export type OrderType = 'MARKET' | 'TP' | 'SL';

export interface PositionTradeHistory {
  timestamp: number;
  action: ActionType;
  price: number;
  amount: bigint;
  fees: bigint;
  token: PerpToken;
  position: Position;
  transactionHash: string;
  orderType: OrderType;
  data: PositionLiquidated | PositionClosed | PositionOpened;
}

export interface PositionStruct {
  id: number;
  trader: Address;
  currency: Address;
  collateralCurrency: Address;
  lastFundingTimestamp: number;
  downPayment: bigint;
  principal: bigint;
  collateralAmount: bigint;
  feesToBePaid: bigint;
}

export interface PerpTradeShare {
  id: number;
  action: ActionType;
  side: 'LONG' | 'SHORT';
  token: PerpToken;
}

export interface PerpInterestPayment {

}

export interface PerpTrade {
  type: ActionType;
  trader: Address;
  timestamp: number;
  downPayment: bigint;
  principal: bigint;
  principalToken: Address;
  collateral: bigint;
  collateralToken: Address;
}

export interface PerpQuoteRequest {
  tokenAddress: Address;
  side: 'long' | 'short';
  downPayment: bigint;
  leverage: number;
  maxSlippage: number;
  speedUp: boolean;
}

export interface Signature {
  v: number;
  r: string;
  s: string;
}

export interface FunctionCallData {
  to: Address;
  value: bigint;
  data: Hex;
}

export interface OpenPositionRequest {
  id: number;
  currency: Address;
  targetCurrency: Address;
  downPayment: bigint;
  principal: bigint;
  minTargetAmount: bigint;
  expiration: number;
  functionCallDataList: FunctionCallData[];
  fee: bigint;
  speedUp: boolean;
}

export interface PerpOrder<T> {
  request: T;
  signature: Signature;
}

export interface CloseQuoteResponse {
  position: Position;
  payout: bigint;
  fee: bigint;
  swapQuote: SwapQuote;
  token: PerpToken;
  liquidationPrice: number;
  sellPrice: number;
  interestOwed: bigint;
  interestOwedEthValue: bigint;
}

export interface ClosePositionRequest {
  expiration: number;
  interest: bigint;
  position: PositionStruct;
  functionCallDataList: FunctionCallData[];
}

export interface Vault {
  address: Address;
  poolAddress: Address;
  tokenAddress: Address;
  name: string;
  symbol: string;
  decimals: number;
  isNative: boolean;
  deprecated: boolean;
}

export interface PoolState {
  poolAddress: Address;
  tokenAddress: Address;
  timestamp: number;
  blockNumber: number;
  utilRate: number;
  apr: number;
  usedBalanceRaw: bigint;
  totalBalanceRaw: bigint;
  sources: PoolStateSources[];
}

export interface PoolStateSources {
  sourceType: string;
  transactionHash: string;
}

export interface VaultStats {
  apr: number;
  tvl: bigint;
  tvlNative: bigint;
  utilizationRate: number;
  vault: Vault;
  poolState: PoolState;
  token?: PerpToken;
  nativeApr?: number | undefined;
  boost?: Boost | undefined;
}

export interface PerpWhiteList {
  address: Address;
  totalWethMinted: number;
  totalEthSent: number;
  lastEthTimestamp: Date | undefined;
  lastWethTimestamp: Date | undefined;
  ethTxn: string | undefined;
  wethTxn: string | undefined;
}

export interface OpenInterest {
  tokenAddress: Address;
  side: 'LONG' | 'SHORT';
  amount: number;
  principal: number;
  numPositions: number;
}

export interface TokenOpenInterestStats {
  longOpenInterest: OpenInterest;
  shortOpenInterest: OpenInterest;
}

export interface OpenInterestStats extends TokenOpenInterestStats {
  token: PerpToken;
}

export interface OpenInterestOverview {
  totalInterest: bigint;
  totalLongInterest: bigint;
  totalShortInterest: bigint;
  numLongPositions: number;
  numShortPositions: number;
  stats: OpenInterestStats[];
}

export interface LPStats {
  totalDeposited: bigint;
  earned: bigint
  pendingInterest: bigint;
  initialDepositTimestamp: Date;
  streakMultiplier: number;
  nextStreakTimestamp: number | undefined;
}

export interface AdminStatsCount {
  totalPositions: number;
  totalTraders: number;
  totalLPs: number;
  totalActiveTraders: number;
  totalActivePositions: number;
  totalActiveLongPositions: number;
  totalActiveShortPositions: number;
  totalVolume: bigint;
  totalFees: bigint;
  totalTvl: bigint;
}

export interface RebalanceEvent {
  transactionHash: string;
  timestamp: number;
  type: 'DEPOSIT' | 'WITHDRAWAL' | 'NATIVE_YIELD';
  vaultAddress: Address;
  address: Address;
  amountInRaw: bigint;
  amountOutRaw: bigint;
}

export interface AdminLpHistory {
  rebalanceEvent: RebalanceEvent;
  vaultStats: VaultStats
}

export interface UserStats {
  address: Address;
  numPositions: number;
  numClosedPositions: number;
  totalPnL: bigint;
  totalDownPayment: bigint;
  totalInterestPaid: bigint;
  totalVolume: bigint;
  favoriteToken: PerpToken | undefined;
  tradePoints: number;
  leaderboardItem: LeaderboardItem | undefined;
}

export interface LeaderboardItem {
  address: Address;
  totalPnLRaw: bigint;
  totalVolumeRaw: bigint;
  numPasses: number;
  tradePoints: number;
  totalSnapshotTradePoints: number;
  periodPoints: number;
  claimableBlastGold: number;
  blastGold: number;
  blastPoints: number;
  lPPoints: number;
}

export interface BannerShareData {
  code: string;
  fileName: string;
}

export interface InfoMessage {
    message: string;
    timestamp: number;
    level: 'INFO' | 'WARNING' | 'ERROR';
}

export interface TokenStats {
  address: string;
  symbol: string;
  price: number;
  priceUsd: number;
  marketCap: number;
  oneHourVolumeUsd: number;
  oneHourChange: number;
  fourHourVolumeUsd: number;
  fourHourChange: number;
  twelveHourVolumeUsd: number;
  twelveHourChange: number;
  oneDayVolumeUsd: number;
  oneDayChange: number;
}

export interface UserBlastPointsStats {
  sentPoints: number;
  outstandingPoints: number;
  pointsPerMs: number;
}

export interface BlastGoldUser {
  address: Address;
  claimableGold: number;
  totalGold: number;
  lastClaimed?: Date | undefined;
}

export interface DailyStats {
  date: number;
  stats: {
    totalVolume: bigint;
    totalFees: bigint;
    numTraders: number;
    numTrades: number;
    newTraders: number;
    percentNonUSD: number;
    percentNonUSDVolume: number;
  };
}

export interface BlastGoldClaim {
  user: BlastGoldUser;
  favoriteTokenAddress: Address;
  type: "LP" | "TRADER";
}

export interface LPVaultSummary {
  address: Address;
  holdings: LPVaultHolding[];
}

export interface LPVaultHolding {
  vaultStats: VaultStats;
  sharesHeld: bigint;
  value: bigint;
  valueInEth: bigint;
  streakMultiplier: number;
  nextStreakTimestamp: number | undefined;
}

export interface BlastinPepesWhitelistData {
  address: string;
  type: string;
  numAllocated: number;
  tag: string | undefined;
  claimed: boolean;
}

export type CompetitionKey = "pnl" | "roi" | "best_trade" | "best_pnl" | "stackable_roi" | "best_pnl_2";

export interface TradeCompetitionEntry {
  address: Address;
  roi: number
  pnl: number;
  pnlRaw: bigint;
  streak: number;
  longestStreak: number;
  subCompetitions: {
    best_trade: number;
    best_pnl: bigint;
    best_pnl_2: bigint;
    stackable_roi: number;
  }
}

export interface TradeCompetitionStats {
  entry?: TradeCompetitionEntry | undefined;
  ranks?: {
    [key in CompetitionKey]: number;
  } | undefined;
  pnl: TradeCompetitionEntry[];
  roi: TradeCompetitionEntry[];
  subCompetitionEntries: TradeCompetitionEntry[];
  subCompetition: SubCompetition;
}

export interface SubCompetition {
  name: string;
  description: string;
  id: CompetitionKey;
  startDate: number;
  endDate: number;
  tokens: string[];
}

export interface Boost {
  type: "TRADE" | "DEPOSIT" | "COMMUNITY";
  id: number;
  slug: string;
  boost: number;
  end?: number | undefined;
}

export interface ClosePositionOrder {
  orderType: number
  positionId: number,
  createdAt: number,
  expiration: number,
  makerAmount: bigint,
  takerAmount: bigint,
  executionFee: bigint,
}

export interface CancelOrder {
  positionId: number;
  orderType: number;
  createdAt: number;
}