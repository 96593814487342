import {CHAIN_ID} from "@/util/constants";
import {isUsdToken, WETH_ADDRESS} from "@/util/chainConstants";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {useEffect, useState} from "react";
import {useUserSelection} from "@/contexts/UserSelectionContext";
export interface Props {
    pairAddress: string;
    address: string;
}

export const DefinedChart = ({pairAddress, address} : Props) => {
    const {showInUsd} = useEthPrice();
    const {userSelections} = useUserSelection();
    const isUSDB = isUsdToken(address);
    const quoteAddress = WETH_ADDRESS;

    const urlParams = new URLSearchParams();

    if (isUSDB || BigInt(address) > BigInt(quoteAddress)) {
        urlParams.append("quoteToken", "token1");
    } else {
        urlParams.append("quoteToken", "token0");
    }

    urlParams.append("quoteCurrency", !isUSDB && showInUsd ? 'USD' : 'TOKEN');
    urlParams.append("embedded", "1");
    urlParams.append("hideTxTable", userSelections.showTxTable ? "0" : "1");
    urlParams.append("hideSidebar", userSelections.showSidebar ? "0" : "1");
    urlParams.append("hideChart", userSelections.showChart ? "0" : "1");
    urlParams.append("embedColorMode", "DEFAULT");

    if (CHAIN_ID === 11155111) {
        if (pairAddress === "0x9459270c3c945E06a9bef86197d606680CDf8EbC") {
            pairAddress = "0x7f0db0d77d0694f29c3f940b5b1f589fff6ef2e0"
        } else if (pairAddress === "0x27a22f685bcdab5ff560f33889e6323b5f126fb0") {
            pairAddress = "0xecb1c17a51d782ac2757e2ab568d159854b9b4bd"
        } else if (pairAddress === "0xa0333d9182fd49fed309cefaaf7aff909260a5f3") {
            pairAddress = "0x267767bFDf1Fc77BC29aeB27e4ADc9462E6490C0" }
    }

    const getSrc = () => `https://www.defined.fi/${CHAIN_ID === 1 ? "eth": "blast"}/${pairAddress}?${urlParams}`;
    const [src, setSrc] = useState(getSrc());

    useEffect(() => {
        setSrc(getSrc());
    }, [showInUsd, address]);

    return (
      <iframe
        key={`${pairAddress}-${address}-${showInUsd}-${userSelections.showChart}-${userSelections.showTxTable}-${userSelections.showSidebar}`}
        height="100%"
        width="100%"
        id="defined-embed"
        title="Defined Embed"
        src={src}
        frameBorder="0"
        allow="clipboard-write" />
    )
}
